<template>
     <footer class="footer">
        <div class="waves">
            <div class="wave" id="wave1"></div>
            <div class="wave" id="wave2"></div>
            <div class="wave" id="wave3"></div>
            <div class="wave" id="wave4"></div>
        </div>
        <div class="footer-fondo-inferior">
            <router-link to="/login" class="link-register">Iniciar sesión</router-link>
        </div>
    </footer>
</template>

<script>
    export default {
        // Footer de todas las vistas
        name: "Footer"
    }
</script>

<style scoped>
    .footer{
        width: 100%;
        position: relative;
        padding-top: 240px;
        overflow: hidden;
    }
    .footer-fondo-inferior{
        position: relative;
        width: 100%;
        height: 200px;
        z-index: 20;
        background: #110133;
    }
    footer .wave {
        position: absolute;
        top: 100px;
        left: 0px;
        background: url(../assets/pictures/wave.svg);
        background-size: 1000px 200px;
        width: 100%;
        height: 200px;
        transform: scale(1.4);
    }

    footer .wave#wave1{
        z-index: 9;
        opacity: 1;
        bottom: 0;
        animation: animateWave 4s linear infinite;
    }
        footer .wave#wave2{
        z-index: 10;
        opacity: 0.2;
        bottom: 100px;
        animation: animateWave_2 4s linear infinite;
    }
        footer .wave#wave3{
        z-index: 9;
        opacity: 0.5;
        bottom: 30px;
        animation: animateWave 3s linear infinite;
    }
        footer .wave#wave4{
        z-index: 10;
        opacity: 0.9;
        bottom: 40px;
        animation: animateWave_2 3s linear infinite;
    }

    @keyframes animateWave {
        0% {
            background-position-x: 1000px;
        }
        100% {
            background-position-x: 0px;
        }
    }
        @keyframes animateWave_2 {
        0% {
            background-position-x: 0px;
        }
        100% {
            background-position-x: 1000px;
        }
    }
</style>