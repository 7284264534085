<template>
    <div class="fondo-degradado-recover"></div>
    <div class="contenedor-register d-flex align-items-center justify-content-center flex-column">
        <h1 class="titulo-register">¡Mejora tu emprendimiento!</h1>
    </div>
</template>

<script>
    import Footer from "../../components/Footer.vue"
    import FormCustom from '../../components/FormCustom.vue'

    export default {
        name: "Register",
        components: {
            Footer,
            FormCustom   
        },

    }
</script>

<style scoped>

</style>